<template>
  <fki-sub-nav
    is-active
    :color="page.color"
    :items="items"
  />
  <fki-container
    fixed
    top="261px"
    left="0"
    width="100%"
    class="fki-contact-page"
  >
    <fki-contact-facility
      v-if="currentFacility"
      :current-facility="currentFacility"
    />
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKISubNav, FKIFooterNav } from '@/components/FKINav'
import { FKIContainer } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'
import { FKIContactFacility } from '@/components/FKIContact'

export default {
  name: 'ContactFacility',
  components: {
    'fki-sub-nav': FKISubNav,
    'fki-container': FKIContainer,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav,
    'fki-contact-facility': FKIContactFacility
  },
  props: {
    facility: {
      type: String,
      default: 'fotokem'
    }
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      siteKey: '6LcahCIUAAAAABuyHMrGOGwlizlur2b0XHVpGwS3',
      currentFacility: null,
      page: { color: 'orange', body: null, slides: [] },
      items: [],
      facilities: {}
    }
  },
  watch: {
    $route: 'checkParams'
  },
  async created () {
    await this.checkParams()
  },
  methods: {
    async checkParams () {
      if (this.$route.path === '/contact') {
        this.$router
          .push({
            path: '/contact/la-studios'
          })
          .catch(() => {})
      }
      await this.getPage()
      await this.getContacts()
      this.getContact()
    },
    async getContacts () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/contacts')
        this.items = []
        this.facilities = result

        for (const key in result) {
          console.log(result)
          if (Object.hasOwnProperty.call(result, key)) {
            let center = null
            let marker = null
            switch (key) {
              case 'la-studios':
                center = { lat: 34.1307579, lng: -118.3604564 }
                break
              case 'margarita-mix-hollywood':
                center = { lat: 34.0885953, lng: -118.3451389 }
                marker = { lat: 34.0885953, lng: -118.3451389 }
                break
              case 'margarita-mix-santa-monica':
                center = { lat: 34.0156263, lng: -118.4890054 }
                marker = { lat: 34.0156263, lng: -118.4890054 }
                break
              default:
                break
            }
            this.items.push({
              slug: 'contact/' + result[key].slug,
              color: this.page.color,
              name: result[key].name
            })
            this.facilities[key].center = center
            this.facilities[key].marker = marker
          }
        }
        console.log(this.items)
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    async getPage () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/page/s/' + this.$route.params.facility)
        this.page = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    getContact () {
      this.currentFacility = this.facilities[this.$route.params.facility]
      this.currentFacility.contact = this.page.contact
      console.log('this.currentFacility')
      console.log(this.currentFacility)
    }
  }
}
</script>
<style lang="scss" scoped>
  .fki-contact-page {
    flex-direction: column;
    bottom:0;
  }
</style>
